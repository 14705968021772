import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ReactPlayer from "react-player";
import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const VideoGallery = () => {
  const videogallerys = [
    { videosrc: "https://youtu.be/8gqMuaePGSM?si=kEWD1O7Jn3B18Gpe" },
    { videosrc: "https://youtu.be/uNxtBeWtA8g?si=9Bpt8Ktu1bh_VtAf" },
    { videosrc: "https://youtu.be/tDJcq9nDC5s?si=lf7lbVynQExiagrC" },
    { videosrc: "https://youtu.be/Fpc4LvmqrDg?si=9D-U9hF4eo3r_FBj" },
    { videosrc: "https://youtu.be/FCYqMSeKFco?si=N4igWrScOz8_Dw83" },
    { videosrc: "https://youtu.be/uXscpkswBoc?si=dckC_fJIu3PMfkHW" },
    { videosrc: "https://youtu.be/65eAbIY7dAo?si=QSkmw1obtWdQF83s" },
  ];
  return (
    <div>
      <MetaTags>
        <title>Video Gallery</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </MetaTags>
      <MainPageTemplate>
        <ServicePageBanner />
        <div className="lg:p-16 sm:p-4 md:p-8">
          <div className="grid xl:grid-cols-3 xxl:grid-cols-4 sm:grid-cols-1 md:grid-cols-2 gap-6">
            {videogallerys.map((videogallery) => (
              <div className="xl:h-[16rem] xxl:h-[20rem] lg:h-[18rem] sm:h-[14rem] rounded-lg">
                <ReactPlayer
                  url={videogallery.videosrc}
                  width="100%"
                  height="100%"
                  controls={true}
                  className="rounded-lg"
                />
              </div>
            ))}
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </div>
  );
};

export default VideoGallery;
