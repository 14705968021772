import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Banner() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  const slides = [
    {
      imageSrc: "/images/banner1.webp",
    },
    {
      imageSrc: "/images/banner2.webp",
    },
    {
      imageSrc: "/images/banner3.webp",
    },
    {
      imageSrc: "/images/banner4.webp",
    },
    {
      imageSrc: "/images/banner5.webp",
    },
    {
      imageSrc: "/images/banner6.webp",
    },
  ];
  return (
    <div className="">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="carousel-item relative rounded-lg " key={index}>
            <div className="w-full relative ">
              <img
                src={slide.imageSrc}
                loading="lazy"
                alt=""
                className="w-full resize   sm:h-auto md:h-auto "
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Banner;
