import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HeadingComponent from "./HeadingComponent";
const HoroscopSection = () => {
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 725) {
        setSlidesToShow(2);
      } else if (window.innerWidth <= 1024) {
        setSlidesToShow(3);
      } else if (window.innerWidth <= 1280) {
        setSlidesToShow(4);
      } else if (window.innerWidth <= 1780) {
        setSlidesToShow(4);
      } else {
        setSlidesToShow(6);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 500,
    pauseOnHover: false,
    arrows: false,
    centerMode: false,
  };
  const horoscops = [
    {
      imgsrc: "images/horoscop1.webp",
      text: "Aquarius",
    },
    {
      imgsrc: "images/horoscop2.webp",
      text: "Cancer",
    },
    {
      imgsrc: "images/horoscop3.webp",
      text: "Caprocorn",
    },
    {
      imgsrc: "images/horoscop4.webp",
      text: "Gemini",
    },
    {
      imgsrc: "images/horoscop5.webp",
      text: "Leo",
    },
    {
      imgsrc: "images/horoscop6.webp",
      text: "Libra",
    },
    {
      imgsrc: "images/horoscop7.webp",
      text: "Pisces",
    },
    {
      imgsrc: "images/horoscop8.webp",
      text: "Sagittarius",
    },
    {
      imgsrc: "images/horoscop9.webp",
      text: "Scorpio",
    },
    {
      imgsrc: "images/horoscop10.webp",
      text: "Taurus",
    },
    {
      imgsrc: "images/horoscop11.webp",
      text: "Virgo",
    },
    {
      imgsrc: "images/horoscop12.webp",
      text: "Aries",
    },
  ];
  return (
    <div className=" ">
      <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
        <HeadingComponent heading={"Daily Horoscope"} />
        <div className=" w-full flex items-center justify-center   ">
          <div className="lg:w-[90%] sm:w-full pl-4">
            <Slider {...settings}>
              {horoscops.map((horoscop, index) => (
                <div className=" " key={index}>
                  <div className="lg:w-[85%] sm:w-[95%] sm:h-full lg:h-full gap-4 items-center justify-center flex flex-col ">
                    <div className="border border-gray-200 rounded-lg">
                      <img
                        src={horoscop.imgsrc}
                        loading="lazy"
                        alt=""
                        className="lg:h-[12rem] lg:w-[15rem] sm:h-[10rem] sm:w-[14rem] resize bg-cover object-contain aspect-auto"
                      />
                    </div>
                    <span className="text-lg font-bold">{horoscop.text}</span>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoroscopSection;
