import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicePageBanner from "../component/ServicePageBanner";

import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const AstrologerInHooghly = () => {
  const content = {
    title1: "Best Astrologer in Hooghly",
    description1:
      "Astrology is an ancient science that involves the planetary positions of planets. Dr. Sridev Shastri is an internationally renowned celebrity astrologer who is also known as the best astrologer in Hooghly. He offers personalized readings related to marriage, love, career, Vastu, and much more. He has spent his entire life in Kolkata, and his study of Vedic astrology has benefitted the citizens of Hooghly in multiple ways. Kolkata is the birthplace of many astrologers out of which Dr. Shastri has now dedicated 30 years here.",
    title2: "Why Is Dr. Shastri the Best Astrologer in Hooghly?",
    description2:
      "Dr. Shastri has 29 years of experience in the Vedic astrology. He has received several National and International Awards in India as well as in Abroad. He has a Ph.D. in Astrology from Singapore. To solve the complexity of life, astrology is one of the most reliable solutions. His predictions are 100% precise. He has published various research papers and books related to astro science that are translated in different languages. So, for love issues, career problems, marriage issues, Kundli matching, tantra puja, palmistry, gemology, numerology, etc. he is the best astrologer in Hooghly.",
  };
  const consultents = [
    {
      title:
        "Dr. Shastri is available for both offline and online consultations with a 50% booking amount. ",
    },
    {
      title:
        "Through Offline, he can be reached out at his chamber in Hooghly. ",
    },
    {
      title:
        "However, for Online consultation, one needs to book an appointment and call these numbers: +91 9051822756 / +917980787603/+91 9051038837",
    },
    {
      title:
        "The Online consultation fee for Overseas Clients is INR 5500 and for others, it is INR 2100. There are various packages provided that include a family package for one year.  ",
    },
    {
      title:
        "If you are going through a difficult time in life then seek his consultation today and schedule a session with Dr. Sridev Shastri.",
    },
  ];
  return (
    <>
      <MetaTags>
        <title>Best Astrologer in Hooghly</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </MetaTags>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />

        <div className="lg:p-16 md:p-8 sm:p-4">
          <div className="flex justify-center flex-col gap-6 items-center text-2xl font-bold ">
            <span className=" text-red-600">
              How to Reach Astrology Consultation Both Online and Offline?
            </span>

            <div className="flex flex-col gap-2 md:text-lg sm:text-base font-semibold ">
              {consultents.map((consultent) => (
                <div className="flex gap-1 items-center">
                  <span>{consultent.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default AstrologerInHooghly;
