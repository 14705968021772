import React from "react";

const HeadingComponent = ({ heading }) => {
  return (
    <div className="flex gap-4  items-center justify-center">
      <div className="h-[1px] sm:w-[4rem] lg:w-[15rem] bg-black"></div>
      <h1 className=" sm:font-bold  md:font-semibold lg:text-4xl sm:text-lg md:text-2xl text-red-600">
        {heading}
      </h1>
      <div className="h-[1px] sm:w-[4rem] lg:w-[15rem] bg-black"></div>
    </div>
  );
};

export default HeadingComponent;
