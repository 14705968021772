import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const FamilyConsultation = () => {
  const content = {
    title1: "Family Consultation:",
    description1:
      "To resolve family problems and conflicts, family consultation provides the right support and guidance for a blissful life. Family is an important part of our life. But sometimes, due to misunderstandings and conflicts between family members, unnecessary problems are created. To resolve this, family consultation is important.  It also predicts how the relationships are going to be in the future and studies the different doshas in each family member’s life. Family is the backbone therefore, to keep it together, consultation is the right path to follow. The right remedies can help to fix problems in the future and help them to lead a good life.",
    title2: "Family Consultation:",
    description2:
      "We always take help from our family members. They are the ones who can support us during hard times. Every problem has a solution and so do family issues. By taking help from an astrologer, family issues can be resolved. It includes the study of compatibility between the family members, and learning about their issues related to property or personal affairs. At a certain point when issues can’t be resolved within the family then family consultation with a renowned astrologer can act as an ointment in their life. By studying the stars and the positions of the planets of each member, proper guidance and support can be provided.",
  };
  return (
    <>
      <MetaTags>
        <title>Family Consultation</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </MetaTags>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default FamilyConsultation;
