import React, { useState } from "react";
import emailjs from "@emailjs/browser";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
const EnquirySection = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
    date: "",
    location: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleDateChange = (date) => {
    setFormData({ ...formData, date });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone } = formData;

    if (!name || !phone) {
      alert("Please fill in all required fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_qthbrhg",
        "template_xxxte42",
        e.target,
        "-ig0XAkykaMriOcqT"
      )
      .then(
        () => {
          navigate("/thank-you");
        },
        (error) => {
          console.log(error.text);
        }
      );

    setFormData({
      name: "",
      phone: "",
      message: "",
      date: "",
      location: "",
    });
  };

  return (
    <div className=" h-fit p-4 rounded-lg bg-black border-[1px] border-blue-900">
      <div className="flex flex-col  gap-8 ">
        <span
          className="flex justify-center snm:text-sm lg:text-xl font-semibold text-yellow-400 items-center
            "
        >
          Book Consultation
        </span>
        <div className="flex text-yellow-400 text-lg flex-col md:gap-2 sm:gap-2 lg:gap-4">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:gap-2 sm:gap-2 lg:gap-8 "
          >
            <span className="flex flex-col gap-1">
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name*"
                type="text"
                className="w-full h-[3rem] bg-transparent border-b-2 border-yellow-400 p-4 placeholder-yellow-400"
              />
            </span>
            <span className="flex flex-col gap-1">
              <input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Mob No*"
                type="text"
                className="w-full h-[3rem] bg-transparent border-b-2 border-yellow-400 p-4 placeholder-yellow-400"
              />
            </span>
            <span className="flex flex-col gap-1">
              <DatePicker
                selected={formData.date}
                onChange={handleDateChange}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="MMMM d, yyyy h:mm aa"
                placeholderText="DOB ( optional ) "
                className="w-full h-[3rem] bg-transparent border-b-2 border-yellow-400 p-4 placeholder-yellow-400 pr-10"
              />
            </span>
            <span className="flex flex-col gap-1">
              <input
                name="location"
                value={formData.location}
                onChange={handleChange}
                type="text"
                placeholder="Location (Optional )"
                className="w-full h-[3rem] bg-transparent border-b-2 border-yellow-400 p-4 placeholder-yellow-400"
              />
            </span>
            <span className="flex flex-col gap-1">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                type="text"
                placeholder="Messege (Optional )"
                className="w-full max-h-[6rem] min-h-[6rem] bg-transparent border-b-2 border-yellow-400 p-4 placeholder-yellow-400"
              />
            </span>
            <span className="w-full flex justify-center transition-transform duration-300 hover:scale-105 items-center">
              <button
                type="submit"
                className="w-[6rem] sm:h-[3rem] lg:h-[2rem] border-[1px] text-black sm:text-lg md:text-sm lg:text-xl font-bold border-gray-300 bg-yellow-400 flex justify-center items-center rounded-lg"
              >
                Submit
              </button>
            </span>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnquirySection;
