import React from "react";

const ServicePageBanner = () => {
  return (
    <div
      className="w-full bg-cover resize"
      style={{
        backgroundImage: `url(/images/servicepagebanner5.webp)`,
      }}
    >
      <div className="flex justify-between w-full">
        <div className=" ">
          <img
            src="images/servicepagebanner6.png"
            alt=""
            className="sm:w-[13rem] md:w-[17rem]  lg:w-[25rem] absolute sm:top-[5.42rem] md:top-[19.52rem] lg:top-[24.2rem]"
          />
        </div>

        <div className="lg:w-[35rem] lg:h-[35rem] md:w-[20rem] md:h-[20rem] sm:w-[12rem] sm:h-[12rem]">
          <img
            src="images/servicepagebanner4.webp"
            alt=""
            className="w-full h-full rotating-image"
          />
        </div>
      </div>
    </div>
  );
};

export default ServicePageBanner;
