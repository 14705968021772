import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import HeadingComponent from "./HeadingComponent";
import { Link } from "react-router-dom";

const BlogSection = ({ showsection }) => {
  const servicecontaint = [
    {
      icons: "images/banner3.webp",

      description:
        "10+ years Career, Business, Money, Health, Love, Marriage Prediction Report.",
    },
    {
      icons: "images/banner1.webp",

      description:
        "Get a detailed analysis of your horoscope compatibility, Financial security, future and & more",
    },
    {
      icons: "images/banner2.webp",

      description:
        "Personalized guidance on life, love, career, business, money",
    },
  ];
  return (
    <div className="lg:p-16 md:p-8 sm:p-4">
      <div className="flex flex-col gap-8 justify-center items-center">
        {showsection && <HeadingComponent heading={" Our Daily Blog"} />}

        <div className="lg:w-[80%] sm:w-[90%]">
          <span className="grid lg:grid-cols-3  md:grid-cols-2 sm:grid-cols-1 gap-8 items-center justify-center place-content-center">
            {servicecontaint.map((service, index) => (
              <div
                key={index}
                className="w-full h-fit  text-center shadow-xl transition-transform duration-300 hover:scale-105  p-2 flex gap-2 flex-col justify-center items-center"
              >
                <span className="h-fit w-full  ">
                  {" "}
                  <img
                    src={service.icons}
                    alt=""
                    className="w-full h-auto rounded-tl-lg rounded-br-lg"
                  />
                </span>

                <span className="description-3-lines !h-[12rem] text-sm text-red-600 ">
                  {service.description}
                </span>
                <div className="flex w-full justify-between px-4   text-xs items-center">
                  <span>13 june, 2023</span>
                  <span className="flex gap-1 items-center">
                    <span> Read More</span>{" "}
                    <span>
                      <FaArrowRightLong />
                    </span>
                  </span>
                </div>
              </div>
            ))}
          </span>
        </div>
        {showsection && (
          <Link
            to={"/blog"}
            className="lg:text-lg underline sm:text-lg md:text-2xl font-semibold text-red-600"
          >
            View All
          </Link>
        )}
      </div>
    </div>
  );
};

export default BlogSection;
