import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PiPaperPlaneRightFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import HeadingComponent from "./HeadingComponent";

const AwardSection = ({ showsection }) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  const slides = [
    {
      imageSrc: "/images/award2.jpg",
    },
    {
      imageSrc: "/images/award3.webp",
    },
    {
      imageSrc: "/images/award4.jpg",
    },
  ];
  const awards = [
    {
      title:
        "Bharat Ratna Dr. B.R Ambedkar Award Recieved by Honorable President Of India Sri. Ramnath Kobind, Delhi",
    },
    { title: "Best Celebrity Astrologer by Actress Madhuri Dixit." },
    {
      title:
        "Lokshahi Sonman 2023 by Honorable Chief Minister of Maharastra Sr. Eknath Shinde.",
    },
    {
      title:
        "Bagdhara Sonman 2023 by Honourable Governor of Kerala Sri. Arif Mohammad Khan.",
    },
    { title: "Most Trusted Astrologer in India Award by Union Ministers." },
    {
      title:
        "Super Indians 24 Award by Union Minister Mr. Jitendra Singh & Celebrity Actor Mr. Govinda",
    },
    { title: "Asian Distinguished Award  2018 – Gold Medal(Singapore)" },
    { title: "Global Achievers Award 2018 (Kolkata)" },
    { title: "Tripura Jyotish  Ratna Award  2018 (Tripura)" },
    { title: "Jyotish Padmavusan Award 2018 ( Tripura)" },
    { title: "Sera Jyotish Sanman 2018 (Kolkata)" },
    { title: "Mata Tripuireswari Award" },
  ];
  return (
    <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
      <HeadingComponent heading={"Achievements"} />
      <div className="flex lg:flex-row-reverse sm:flex-col gap-4 items-center ">
        <div className="lg:w-[50%] rounded-lg sm:w-full flex items-center justify-center">
          <div className="w-full">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <div className="carousel-item relative rounded-lg " key={index}>
                  <div className="w-full relative ">
                    <img
                      src={slide.imageSrc}
                      loading="lazy"
                      alt=""
                      className="w-full resize rounded-lg  sm:h-auto md:h-auto "
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="flex flex-col gap-6 lg:w-[50%] sm:w-full">
          <div className="flex flex-col gap-2 md:text-lg sm:text-base font-semibold text-red-600">
            {awards.map((award) => (
              <div className="flex gap-1 items-center">
                <span>
                  <PiPaperPlaneRightFill />
                </span>
                <span>{award.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showsection && (
        <div className="flex justify-center items-center w-full">
          <Link
            to="/awards-and-achievements"
            className="text-lg underline font-bold text-red-600"
          >
            View All
          </Link>
        </div>
      )}
    </div>
  );
};
export default AwardSection;
