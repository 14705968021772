import React from "react";
import CountUp from "react-countup";
import HeadingComponent from "./HeadingComponent";

const CountingSection = () => {
  return (
    <div className=" lg:mt-16 md:mt-8 sm:mt-4">
      <HeadingComponent heading={"Why Choose Us"} />
      <div className="md:p-8 lg:p-16 sm:p-4 w-full flex justify-center items-center">
        <div className="grid lg:grid-cols-4 sm:grid-cols-2  self-center sm:gap-8 lg:gap-20 ">
          <div className="flex flex-row  gap-2 items-center ">
            <span>
              <img
                src="images/counting1.svg"
                alt=""
                className="md:h-16 md:w-16 sm:w-10 sm:h-10"
              />
            </span>
            <span className="flex flex-col lg:items-start sm:items-center lg:justify-start sm:justify-center">
              <span className="flex gap-1 items-center lg:text-2xl md:text-lg sm:text-sm font-bold ">
                <CountUp end={30} duration={2} enableScrollSpy />+
              </span>
              <span className="lg:text-2xl md:text-lg sm:text-[12px] text-red-600 font-semibold">
                Awards Won
              </span>
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center ">
            <span>
              <img
                src="images/counting3.svg"
                alt=""
                className="md:h-16 md:w-16 sm:w-10 sm:h-10"
              />
            </span>
            <span className="flex flex-col lg:items-start sm:items-center lg:justify-start sm:justify-center">
              <span className="flex gap-1 items-center lg:text-2xl md:text-lg sm:text-sm font-bold">
                <CountUp end={30} duration={2} enableScrollSpy />+
              </span>
              <span className="lg:text-2xl md:text-lg sm:text-[12px] text-red-600 font-semibold">
                Years Experience
              </span>
            </span>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <span>
              <img
                src="images/counting5.svg"
                alt=""
                className="md:h-16 md:w-16 sm:w-10 sm:h-10"
              />
            </span>
            <span className="flex flex-col  lg:items-start sm:items-center lg:justify-start sm:justify-center ">
              <span className="flex gap-1 items-center lg:text-2xl md:text-lg sm:text-sm font-bold">
                <CountUp end={50} duration={2} enableScrollSpy />
                K+
              </span>
              <span className="lg:text-2xl md:text-lg sm:text-[12px] text-red-600 font-semibold">
                Satified Clients
              </span>
            </span>
          </div>
          <div className="flex flex-row  gap-2 items-center">
            <span>
              <img
                src="images/counting4.svg"
                alt=""
                className="md:h-16 md:w-16 sm:w-10 sm:h-10"
              />
            </span>
            <span className="flex flex-col  lg:items-start sm:items-center lg:justify-start sm:justify-center ">
              <span className="flex gap-1 items-center lg:text-2xl md:text-lg sm:text-sm font-bold">
                <CountUp end={10} duration={2} enableScrollSpy />+
              </span>
              <span className="lg:text-2xl md:text-lg sm:text-[12px] text-red-600 font-semibold">
                Chambers
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountingSection;
