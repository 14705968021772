import React, { useEffect, useState } from "react";
import { RiCloseLine, RiMenuAddLine } from "react-icons/ri";
import { Link } from "react-router-dom";
const Header = () => {
  const [dropdownStates, setDropdownStates] = useState({});
  const [menuopen, setMenuopen] = useState(false);

  const togglemenuopen = () => {
    setMenuopen(!menuopen);
  };

  const toggleDropdown = (index) => {
    setDropdownStates({
      ...dropdownStates,
      [index]: !dropdownStates[index],
    });
  };
  const NavElement = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about-us" },
    {
      name: "Chambers ",
      dropdownItems: [
        { name: "Astrologer in Hooghly", link: "/astrologer-in-hooghly" },
        { name: "Astrologer in Howrah ", link: "/astrologer-in-howrah" },
        { name: "Astrologer in India ", link: "/astrologer-in-india" },
      ],
    },
    {
      name: "Services",
      dropdownItems: [
        { name: "Janam Kundali Chart", link: "/janam-kundali-chart" },
        { name: "Couple Match Making", link: "/couple-match-making" },
        { name: "Online Consultation", link: "/online-consultation" },
        { name: "Family Consultation", link: "/family-consultation" },
        { name: "Corporate Astrology", link: "/corporate-astrology" },
        { name: "Career Astrology", link: "/career-astrology" },
        { name: "Marriage Astrology", link: "/marriage-astrology" },
        { name: "Health Astrology", link: "/health-astrology" },
      ],
    },
    { name: "Gemstone", link: "/" },
    { name: "Free Calculator", link: "/" },
    {
      name: "Media ",
      dropdownItems: [
        { name: "Awards & Achievements", link: "/awards-and-achievements" },
        { name: "Press Release", link: "/press-release" },
        { name: "Photo Gallery ", link: "/photo-gallery" },
        { name: "Video Gallery ", link: "/video-gallery" },
      ],
    },
    { name: "Blog", link: "/blog" },

    { name: "Contact Us", link: "/contact-us" },
  ];
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 60) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={`lg:bg-black sm:bg-white fixed z-50 w-full ${
        scrolled ? "  top-0  header-transition" : ""
      }`}
    >
      <div
        className="lg:flex sm:hidden justify-end gap-10 p-4 px-9 relative"
        onMouseLeave={() => {
          setDropdownStates(false);
        }}
      >
        {NavElement.map((navbar, index) => (
          <div key={index} className="font-semibold text-yellow-400">
            {navbar.dropdownItems ? (
              <div className="relative ">
                <button
                  onClick={() => toggleDropdown(index)}
                  onMouseEnter={() => toggleDropdown(index)}
                  className="flex flex-row gap-2 items-center "
                >
                  <div>{navbar.name} </div>
                  <span className=" text-sm">
                    {dropdownStates[index] ? "▲" : "▼"}
                  </span>
                </button>
                {dropdownStates[index] && (
                  <div className="w-full flex items-center justify-center z-50 ">
                    <div className=" bg-white absolute  top-8 rounded-lg shadow-md mt-1 p-2 w-[15rem]">
                      {navbar.dropdownItems.map((item, i) => (
                        <Link
                          key={i}
                          to={item.link}
                          className="flex py-2 px-4 text-gray-800 hover:bg-gray-200"
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link to={navbar.link}>{navbar.name}</Link>
            )}
          </div>
        ))}
      </div>
      <div className="sm:flex md:justify-end md:items-end  sm:justify-between sm:items-center w-full md:p-3 sm:p-2 px-9 lg:hidden">
        <div className="md:hidden">
          <img
            src="images/sitelogo.png"
            alt=""
            className="h-[3rem] w-[10rem]"
          />
        </div>
        <button
          onClick={togglemenuopen}
          className="border-2 p-1 border-gray-700 rounded-lg bg-red-500"
        >
          {menuopen ? (
            <RiCloseLine className="sm:text-[1.2rem] md:text-3xl text-white font-semibold" />
          ) : (
            <RiMenuAddLine className="sm:text-[1.2rem] md:text-3xl text-white font-semibold" />
          )}
        </button>
      </div>
      {menuopen && (
        <div className="sm:flex bg-black flex-col sm:h-[50vh] md:h-[40vh] md:text-3xl sm:text-xl overflow-scroll lg:hidden  px-9   relative">
          {NavElement.map((navbar, index) => (
            <div
              key={index}
              className="font-semibold text-yellow-500  p-4 md:py-10 border-b-2 border-gray-200"
            >
              {navbar.dropdownItems ? (
                <div className="relative ">
                  <button
                    onClick={() => toggleDropdown(index)}
                    onMouseEnter={() => toggleDropdown(index)}
                    className="flex flex-row gap-2 items-center "
                  >
                    <div>{navbar.name} </div>
                    <span className=" text-xl">
                      {dropdownStates[index] ? "▲" : "▼"}
                    </span>
                  </button>
                  <div className="w-full flex items-center justify-center z-50 relative top-0 ">
                    {dropdownStates[index] && (
                      <div className="relative bg-black rounded-lg shadow-md mt-1 p-2 w-full transition-opacity border-gray-200 text-yellow-400  opacity-100">
                        {navbar.dropdownItems.map((item, i) => (
                          <Link
                            key={i}
                            to={item.link}
                            className="block py-4 px-4 border-b-[0.5px] border-gray-200 text-yellow-400 "
                          >
                            {item.name}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Link to={navbar.link}>{navbar.name}</Link>
              )}
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

export default Header;
