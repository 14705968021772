import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicePageBanner from "../component/ServicePageBanner";

import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const AstrologerInHowrah = () => {
  const content = {
    title1: "Best Astrologer in Howrah",
    description1:
      "When it comes to Astrology, precision is more important rather than facts. In Vedic Astrology, Dr. Sridev Shastri is a renowned name who is known for his 30 years of experience in this field. For solving astrology-related problems, he is known as the Best Astrologer in Howrah. His areas of expertise include issues in marriage life, career, love-related issues, health, and Vastu. He is a Senior Astro Specialist who was also awarded by the ex-President of India, Sri Ramnath Kobind.",
    title2: "Why Is Dr. Shastri the Best Astrologer in Howrah?",
    description2:
      "People of Howrah adhere to their roots and astrology is a common practice adopted there. When seeking astrological guidance, Dr. Shashti has gained an immense reputation in astrology and represented it at several international awards such as the International Glory Award and the 40th annual International Congress. He is also awarded by celebrities such as Madhuri Dixit, as the best celebrity astrologer in India.",
    description3:
      " Dr Shastri has 24K Clients Globally and is known for his 100% true prediction. Be it Justdial, Facebook, or Google, his work is legitimate and reviewed by the clients. He can predict personal relationships as well as professional life and provide the best consultation to fix money-related issues as well. ",
  };
  const consultents = [
    {
      title:
        "Appointment to seek consultation can be booked either online or at his physical chamber at Howrah with a 50% booking fee and has a fee of INR 2100. ",
    },
    {
      title:
        " His Online consultation fee is INR 2100 and for Overseas clients, it is INR 5500. He also provides a family consultation package at INR 21000 that is valid for 1 year.",
    },
    {
      title:
        "If you are looking for quick solutions related to marriage, love. Family, career, or Vastu tips, then book an online consultation today by calling these numbers - +91 9051822756/ +917980787603",
    },
  ];
  return (
    <>
      <MetaTags>
        <title>
          Best Astrologer in Howrah - Dr. Sridev Shastri | President Awardee
          Astrologer
        </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </MetaTags>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <div className="lg:p-16 md:p-8 sm:p-4">
          <div className="flex justify-center flex-col gap-6 items-center text-2xl font-bold">
            <span className=" text-red-600">
              How to Reach Astrology Consultation Both Online and Offline?
            </span>

            <div className="flex flex-col gap-2 md:text-lg sm:text-base font-semibold ">
              {consultents.map((consultent) => (
                <div className="flex gap-1 items-center">
                  <span>{consultent.title}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default AstrologerInHowrah;
