import React, { useEffect } from "react";
import Header from "../component/Header";
import TopHeader from "../component/TopHeader";

import Scrolltop from "./Scrolltop";
import OnlyMobile from "./OnlyMobile";

const MainPageTemplate = ({ children }) => {
  useEffect(() => {
    // Scroll to the top of the page when component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="flex font-quattrocento w-full h-full flex-col  overflow-x-hidden ">
        <div>
          <TopHeader />
        </div>
        <div className="">
          <Header />
        </div>
        <div className=" sm:mt-[4rem] md:mt-[4rem] lg:mt-[3.5rem]">
          {children}
        </div>

        <div>
          <OnlyMobile />
        </div>
        <div>
          <Scrolltop />
        </div>
      </div>
    </div>
  );
};

export default MainPageTemplate;
