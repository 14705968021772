import React from "react";
import { Link } from "react-router-dom";

const TopHeader = () => {
  return (
    <div className="    bg-white z-[60] w-full">
      <div className="md:flex sm:hidden justify-between items-center sm:flex-col sm:gap-2 lg:gap-0 lg:flex-row p-4 px-8">
        <div className="">
          <img
            src="images/sitelogo.png"
            alt=""
            className="h-[4rem] w-[15rem]"
          />
        </div>
        <div className="flex gap-4 text-black font-semibold sm:text-xs  md:text-lg">
          <Link
            to="https://payu.in/pay/7094D4D6A58E5D17B27701CBEAA6FE81"
            className="sm:w-[10rem] rounded-sm md:w-[15rem] p-2 h-[3rem] bg-black text-yellow-400 hover:text-xl flex justify-center gap-4 items-center"
          >
            <span>Pay Now</span>
            <span>
              <img src="images/topnav1.svg" alt="" className="w-8 h-8" />
            </span>
          </Link>
          <Link
            to="/contact-us"
            className="sm:w-[10rem] rounded-sm md:w-[15rem] p-2 h-[3rem] bg-yellow-500 hover:text-xl flex justify-center gap-4 items-center"
          >
            <span>Enquiry Now</span>
            <img src="images/topnav2.svg" alt="" className="w-10 h-10" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
