import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import BlogSection from "../component/BlogSection";
import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

const BlogPage = () => {
  return (
    <>
      <MetaTags>
        <title>Our Daily Blogs </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </MetaTags>
      <MainPageTemplate>
        <ServicePageBanner />
        <BlogSection showsection={false} />
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default BlogPage;
