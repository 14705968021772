import React from "react";
import HeadingComponent from "./HeadingComponent";
import { Link } from "react-router-dom";

const NewsFeaturedSection = ({ showHeading, isactive }) => {
  const newschannel = [
    {
      imgsrc: "images/news1.webp",
      link: "",
    },
    {
      imgsrc: "images/news2.webp",
      link: "https://news.abplive.com/brand-wire/best-astrologer-in-kolkata-dr-sridev-shastri-awarded-by-governor-of-west-bengal-1629013",
    },
    {
      imgsrc: "images/news3.webp",
      link: "https://www.ahmedabadmirror.com/top-astrologer-in-howrah/81861641.html",
    },
    {
      imgsrc: "images/news4.webp",
      link: "",
    },
    {
      imgsrc: "images/news5.webp",
      link: "",
    },
    {
      imgsrc: "images/news6.webp",
      link: "",
    },
    {
      imgsrc: "images/news7.webp",
      link: "",
    },
    {
      imgsrc: "images/news8.webp",
      link: "",
    },
    {
      imgsrc: "images/news9.webp",
      link: "https://www.hindustantimes.com/brand-stories/top-3-best-astrologers-in-kolkata-latest-list-of-2024-updated-101703689564790.html",
    },
    {
      imgsrc: "images/news10.webp",
      link: "https://www.justdial.com/Howrah/Dr-Sridev-Shastri-Opposite-Howra-Sarat-Sadan-Howrah-Traffic-Guard-Howrah-Junction/9999PXX33-XX33-200305185814-D7I2_BZDET",
    },
    {
      imgsrc: "images/news11.webp",
      link: "",
    },
    {
      imgsrc: "images/news12.webp",
      link: "",
    },
    {
      imgsrc: "images/news13.webp",
      link: "https://www.republicworld.com/initiatives/best-astrologer-in-kolkata-president-awardee-dr-sridev-shastri-articleshow/",
    },
    {
      imgsrc: "images/news14.webp",
      link: "",
    },
    {
      imgsrc: "images/news15.webp",
      link: "https://www.mid-day.com/brand-media/events/article/best-astrologer-in-india-dr-sridev-shastri-353",
    },
    {
      imgsrc: "images/news16.webp",
      link: "https://www.thehindu.com/brandhub/pr-release/dr-sridev-shastri-the-most-trusted-astrologer-in-india/article66439678.ece#:~:text=Sridev%20Shastri.,makes%20him%20the%20top%20astrologer.",
    },
    {
      imgsrc: "images/news17.webp",
      link: "https://theprint.in/theprint-valuead-initiative/best-5-astrologer-in-kolkata-2024-west-bengal-india/1913083/",
    },
    {
      imgsrc: "images/news18.webp",
      link: "",
    },
    {
      imgsrc: "images/news19.webp",
      link: "https://threebestrated.in/astrologers-in-howrah-wb",
    },
    {
      imgsrc: "images/news20.webp",
      link: "",
    },
  ];
  return (
    <div className=" ">
      <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
        {showHeading && <HeadingComponent heading={"Featured In News"} />}

        <div className=" w-full flex items-center justify-center   ">
          <div className="grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8">
            {newschannel.map((news, index) =>
              isactive ? (
                <Link
                  to={news.link}
                  target="_blank"
                  key={index}
                  className=" shadow-md"
                >
                  <img src={news.imgsrc} alt="" />
                </Link>
              ) : (
                <div className=" shadow-md" key={index}>
                  <img src={news.imgsrc} alt="" />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsFeaturedSection;
