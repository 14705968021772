import React from "react";

import MainPageTemplate from "../element/MainPageTemplate";
import Banner from "../component/Banner";
import AboutSection from "../component/AboutSection";

import CountingSection from "../component/CountingSection";

import FooterSection from "../component/FooterSection";
import { MetaTags } from "react-meta-tags";

import ServicesSection from "../component/ServicesSection";
import AwardSection from "../component/AwardSection";
import BlogSection from "../component/BlogSection";
import HoroscopSection from "../component/HoroscopSection";
import GallarySection from "../component/GallarySection";
import NewsFeaturedSection from "../component/NewsFeaturedSection";
import TestimonialSection from "../component/TestimonialSection";

const Home = () => {
  return (
    <>
      <MetaTags>
        <title>
          Best Astrologer in Howrah - Dr. Sridev Shastri | President Awardee
          Astrologer
        </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </MetaTags>

      <MainPageTemplate>
        <Banner />
        <AboutSection showButton={true} />
        <HoroscopSection />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Couple Match Making",
            "Online Consultation",
            "Family Consultation",
            "Corporate Astrology",
            "Career Astrology",
            "Marriage Astrology",
            "Health Astrology",
          ]}
          serviceheading={"Our Services"}
        />
        <GallarySection showHeading={true} />
        <CountingSection />
        <NewsFeaturedSection showHeading={true} />
        <AwardSection showsection={true} />

        <BlogSection showsection={true} />
        <TestimonialSection />
        <FooterSection />
      </MainPageTemplate>
    </>
  );
};

export default Home;
