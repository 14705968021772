import React from "react";
import EnquirySection from "./EnquirySection";

const ServicePagesComponent = ({ content }) => {
  const { title1, description1, title2, description2, description3 } = content;
  return (
    <div>
      <div className="flex lg:flex-row sm:flex-col sm:p-4 md:p-8 lg:p-16">
        <div className="lg:w-[70%] sm:w-full ">
          <div className="flex flex-col gap-6 sm:p-2 lg:p-8 text-xl ">
            <span className="text-2xl text-red-600 font-bold">{title1}</span>
            <span>{description1}</span>
            <span className="text-2xl text-red-600 font-bold">{title2}</span>
            <span>{description2}</span>
            <span>{description3}</span>
          </div>
        </div>
        <div className="lg:w-[30%] sm:w-full">
          <EnquirySection />
        </div>
      </div>
    </div>
  );
};

export default ServicePagesComponent;
