import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import FooterSection from "../component/FooterSection";

const ThankYouPage = () => {
  return (
    <div>
      <MainPageTemplate>
        <div className="lg:p-16 md:p-8 sm:p-4">
          <div className="flex flex-col text-center items-center gap-4 justify-center text-2xl font-bold">
            <span>
              <img
                src="images/thankyou.png"
                alt=""
                className="w-[5rem] h-[5rem]"
              />
            </span>
            <span>Thank You For submitting details</span>
            <span> Our Team will Contact with You Soon!</span>
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </div>
  );
};

export default ThankYouPage;
