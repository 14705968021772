import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

import AboutPage from "./pages/AboutPage";
import ContactUs from "./pages/ContactUs";
import JanamKundaliChart from "./pages/JanamKundaliChart";
import CoupleMatchMaking from "./pages/CoupleMatchMaking";
import OnlineConsultation from "./pages/OnlineConsultation";
import FamilyConsultation from "./pages/FamilyConsultation";
import CorporateAstrology from "./pages/CorporateAstrology";
import CareerAstrology from "./pages/CareerAstrology";
import MarriageAstrology from "./pages/MarriageAstrology";
import HealthAstrology from "./pages/HealthAstrology";
import AstrologerInHowrah from "./pages/AstrologerInHowrah";
import AstrologerInHooghly from "./pages/AstrologerInHooghly";
import AstrologerInIndia from "./pages/AstrologerInIndia";
import AwardsAndAchievements from "./pages/AwardsAndAchievements";
import PhotoGallery from "./pages/PhotoGallery";
import PressRelease from "./pages/PressRelease";
import BlogPage from "./pages/BlogPage";
import VideoGallery from "./pages/VideoGallery";
import AddPageBestAstrologer from "./pages/AddPageBestAstrologer";
import ThankYouPage from "./pages/ThankYouPage";

function App() {
  return (
    <div className=" ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>

          <Route path="/about-us" element={<AboutPage />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route
            path="/janam-kundali-chart"
            element={<JanamKundaliChart />}
          ></Route>
          <Route
            path="/couple-match-making"
            element={<CoupleMatchMaking />}
          ></Route>
          <Route
            path="/online-consultation"
            element={<OnlineConsultation />}
          ></Route>
          <Route
            path="/family-consultation"
            element={<FamilyConsultation />}
          ></Route>
          <Route path="/career-astrology" element={<CareerAstrology />}></Route>
          <Route
            path="/corporate-astrology"
            element={<CorporateAstrology />}
          ></Route>
          <Route path="/career-astrology" element={<CareerAstrology />}></Route>
          <Route
            path="/marriage-astrology"
            element={<MarriageAstrology />}
          ></Route>
          <Route path="/health-astrology" element={<HealthAstrology />}></Route>
          <Route
            path="/astrologer-in-howrah"
            element={<AstrologerInHowrah />}
          ></Route>
          <Route
            path="/astrologer-in-hooghly"
            element={<AstrologerInHooghly />}
          ></Route>
          <Route
            path="/astrologer-in-india"
            element={<AstrologerInIndia />}
          ></Route>
          <Route
            path="/awards-and-achievements"
            element={<AwardsAndAchievements />}
          ></Route>
          <Route path="/photo-gallery" element={<PhotoGallery />}></Route>
          <Route path="/press-release" element={<PressRelease />}></Route>
          <Route path="/blog" element={<BlogPage />}></Route>
          <Route path="/video-gallery" element={<VideoGallery />}></Route>
          <Route
            path="/best-astrologer"
            element={<AddPageBestAstrologer />}
          ></Route>
          <Route path="/thank-you" element={<ThankYouPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
