import React, { useEffect, useState } from "react";
import { RiArrowUpDoubleFill } from "react-icons/ri";

const Scrolltop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
      setIsAnimated(true);
    } else {
      setIsVisible(false);
      setIsAnimated(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div
      className={`fixed sm:bottom-20 md:bottom-10 right-0 p-4 flex z-[100]  justify-between w-fit items-end transition-opacity ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div
        className={`bg-red-600 text-3xl  text-yellow-400 rounded-full justify-center items-center flex w-[3rem] h-[3rem] cursor-pointer ${
          isAnimated ? "slidefromright" : ""
        }`}
        onClick={scrollToTop}
      >
        <span>
          <RiArrowUpDoubleFill />
        </span>
      </div>
    </div>
  );
};

export default Scrolltop;
