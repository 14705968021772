import React, { useState } from "react";
import HeadingComponent from "./HeadingComponent";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";

const GallarySection = ({ showHeading }) => {
  const gallaryImgs = [
    {
      imgsrc: "images/gallery1.jpg",
      text: "International Glory Award 2021 at Goa Awarded by Mr Sonu Sood",
    },
    {
      imgsrc: "images/gallery2.jpg",
      text: "Asian Pacific Award by Actress Ms. Amisha Patel",
    },
    { imgsrc: "images/gallery3.jpg", text: "At Golden Alory Award" },
    { imgsrc: "images/gallery4.jpg", text: "Asia Pacific Excellence Award" },
    {
      imgsrc: "images/gallery5.jpg",
      text: "Best Astrologer in WB by Honorable Governor Of West Bengal",
    },
    { imgsrc: "images/gallery6.jpg", text: "Golden Glory Awards 2021" },
    {
      imgsrc: "images/gallery7.jpg",
      text: "Dr Sridev Shastri in Golden Glory Award",
    },
    {
      imgsrc: "images/gallery8.jpg",
      text: "Most Trusted Astrologer in India Awarded by Union Ministers at Atal Achievement Award, Delhi.",
    },
    {
      imgsrc: "images/gallery9.jpg",
      text: "Bharat Ratna Dr. B.R Ambedkar Award by President Of India Sri. Ramnath Kobind.",
    },
    {
      imgsrc: "images/gallery10.jpg",
      text: "Best Celebrity Astrologer in India, Awarded by Ms. Madhuri Dixit",
    },
    {
      imgsrc: "images/gallery11.jpg",
      text: "Star Achiever Award by Actress Ms. Bhagashree",
    },
    {
      imgsrc: "images/gallery12.jpg",
      text: "Lokshahi Sanman, Awarded by Honorable CM of Mumbai Sri. Eknath Shinde",
    },
    {
      imgsrc: "images/gallery13.jpg",
      text: "Most Trusted Astrologer Award By Minister Of Central Govt",
    },
    {
      imgsrc: "images/gallery14.jpg",
      text: "okshahi Sanman, Awarded by Honorable CM of Mumbai Sri. Eknath Shinde",
    },
    {
      imgsrc: "images/gallery15.jpg",
      text: "Guest Of Honor at Global Fame Awards 2022, at Kolkata recieved from Bipasa Basu",
    },
  ];

  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const openModal = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateImage = (direction) => {
    if (direction === "prev") {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? gallaryImgs.length - 1 : prevIndex - 1
      );
    } else if (direction === "next") {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === gallaryImgs.length - 1 ? 0 : prevIndex + 1
      );
    }
  };
  return (
    <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
      {showHeading && <HeadingComponent heading={"Gallery"} />}
      <div className="flex w-full justify-center items-center">
        <div className="grid lg:grid-cols-4 sm:grid-cols-2  sm:gap-4 lg:gap-6">
          {gallaryImgs.map((gallary, index) => (
            <div key={index} className="h-auto w-fit">
              <img
                src={gallary.imgsrc}
                onClick={() => openModal(index)}
                alt=""
                className="w-fit h-full cursor-pointer"
              />
            </div>
          ))}
        </div>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full z-[100] flex justify-center items-center bg-black bg-opacity-80">
          <div className="relative w-full flex justify-center items-center">
            <div className="flex flex-col gap-4 items-center justify-center">
              <span>
                <img
                  src={gallaryImgs[selectedImageIndex].imgsrc}
                  alt=""
                  className="lg:w-fit h-fit sm:w-full "
                />
              </span>
              <span className="text-xl font-bold text-white">
                {gallaryImgs[selectedImageIndex].text}
              </span>
            </div>
            <div className="absolute w-full flex justify-between  p-4">
              <FaChevronLeft
                className="md:text-white sm:text-black text-3xl  cursor-pointer"
                onClick={() => navigateImage("prev")}
              />
              <FaChevronRight
                className="md:text-white sm:text-black text-3xl cursor-pointer"
                onClick={() => navigateImage("next")}
              />
            </div>
          </div>
          <div className="top-0 absolute right-0 p-16">
            <FaTimes
              className="text-white text-3xl cursor-pointer"
              onClick={closeModal}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GallarySection;
