import React from "react";
import { Link } from "react-router-dom";
import HeadingComponent from "./HeadingComponent";

const ServicesSection = ({ selectedServices, serviceheading }) => {
  const servicecontaint = [
    {
      icons: "images/kundli.svg",
      heading: "Janam Kundali Chart",
      description:
        "To predict the future in astrology, Janam Kundli's reading is important....",
      link: "/janam-kundali-chart",
    },
    {
      icons: "images/Couplematchmaking.svg",
      heading: "Couple Match Making",
      description:
        "Marriage is one of the purest relations that requires mutual efforts but to make it ...",
      link: "/couple-match-making",
    },
    {
      icons: "images/onlineconsultation.svg",
      heading: "Online Consultation",
      description:
        "Get personalized consultation on various issues with our Online Consultation services...",
      link: "/online-consultation",
    },
    {
      icons: "images/Familyconsultation.svg",
      heading: "Family Consultation",
      description:
        "To resolve family problems and conflicts, family consultation...",
      link: "/family-consultation",
    },
    {
      icons: "images/Corporateastrology.svg",
      heading: "Corporate Astrology",
      description:
        "Corporate Astrology or Business Astrology refers to applying techniques and strategies...",
      link: "/corporate-astrology",
    },
    {
      icons: "images/Careerastrology.svg",
      heading: "Career Astrology",
      description:
        "Nowadays, getting a job is hard, and choosing the right career...",
      link: "/career-astrology",
    },
    {
      icons: "images/Marriageastrology.svg",
      heading: "Marriage Astrology",
      description:
        "Astrology can help to find a suitable partner and provide guidance for a happy married life...",
      link: "/marriage-astrology",
    },
    {
      icons: "images/Healthastrology.svg",
      heading: "Health Astrology",
      description:
        "Health Astrology can assess diseases and provide the strength...",
      link: "/health-astrology",
    },
  ];
  const servicesToShow = servicecontaint.filter((service) =>
    selectedServices.includes(service.heading)
  );
  return (
    <div className="lg:p-16 md:p-8 sm:p-4">
      <div className="flex flex-col gap-8 justify-center items-center">
        <HeadingComponent heading={serviceheading} />
        <div className="lg:w-[80%] sm:w-[90%]">
          <span className="grid lg:grid-cols-4  md:grid-cols-2 sm:grid-cols-1 gap-8 items-center justify-center place-content-center">
            {servicesToShow.map((service, index) => (
              <div
                key={index}
                className="w-full h-fit  text-center  shadow-md transition-transform duration-300 hover:scale-105 p-4 flex gap-2 flex-col justify-center items-center"
              >
                <span className="h-[6rem] flex">
                  <span className="h-fit w-fit p-3 rounded-full   ">
                    {" "}
                    <img
                      src={service.icons}
                      alt=""
                      className="md:h-20 md:w-20 sm:w-20 sm:h-20"
                    />
                  </span>
                </span>
                <div className="text-xl h-[3rem] font-bold text-red-600">
                  {service.heading}
                </div>
                <span className="text-[16px] h-[5rem] ">
                  {service.description}
                </span>
                <Link to={service.link} className="h-[4rem] flex">
                  <button className="service-button text-base w-[10rem] h-[3rem] flex justify-center items-center font-bold">
                    Read More
                  </button>
                </Link>
              </div>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
